var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "barge-device", attrs: { id: "bargeDevice" } },
    [
      _c(
        "el-row",
        {
          staticStyle: { width: "100%" },
          attrs: { type: "flex", align: "center" },
        },
        [
          _c(
            "el-col",
            { staticClass: "charts-wp", attrs: { span: 7 } },
            [
              _c(
                "el-form",
                { staticClass: "form-wp" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 15 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 15 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t("pleaseSelectBarge"),
                                    size: "small",
                                  },
                                  on: { change: _vm.onSearch },
                                  model: {
                                    value: _vm.bargeName,
                                    callback: function ($$v) {
                                      _vm.bargeName = $$v
                                    },
                                    expression: "bargeName",
                                  },
                                },
                                _vm._l(_vm.bargeList, function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { size: "small" },
                                  on: { change: _vm.intervalGetDatas },
                                  model: {
                                    value: _vm.intervalTime,
                                    callback: function ($$v) {
                                      _vm.intervalTime = $$v
                                    },
                                    expression: "intervalTime",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "2S " + _vm.$t("update"),
                                      value: "2",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "5S " + _vm.$t("update"),
                                      value: "5",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "10S " + _vm.$t("update"),
                                      value: "10",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "float-left-top" },
                [
                  _c(
                    "dv-border-box-12",
                    {
                      staticClass: "border-item",
                      attrs: {
                        color: ["#003b51", "#109eb5"],
                        backgroundColor: "#041d35",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "center" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("ve-gauge", {
                                attrs: {
                                  data: _vm.speedChartDataP,
                                  height: "220px",
                                  settings: _vm.chartSettings,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticClass: "flex-state", attrs: { span: 8 } },
                            [
                              _c("h3", { staticClass: "state-name" }, [
                                _vm._v("C.O.P (P)"),
                              ]),
                              _vm.pumpOn.P
                                ? _c(
                                    "em",
                                    {
                                      staticClass: "lamp",
                                      staticStyle: {
                                        "background-color": "#5dc12b",
                                        "border-color": "#d4ffdd",
                                      },
                                    },
                                    [_vm._v("RUN")]
                                  )
                                : _vm._e(),
                              !_vm.pumpOn.P
                                ? _c(
                                    "em",
                                    {
                                      staticClass: "lamp",
                                      staticStyle: {
                                        "background-color": "#d05050",
                                        "border-color": "#f1d6d6",
                                      },
                                    },
                                    [_vm._v("STOP")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "dv-border-box-12",
                    {
                      staticClass: "border-item",
                      attrs: {
                        color: ["#003b51", "#109eb5"],
                        backgroundColor: "#041d35",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "center" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("ve-gauge", {
                                attrs: {
                                  data: _vm.speedChartDataS,
                                  height: "220px",
                                  settings: _vm.chartSettings,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticClass: "flex-state", attrs: { span: 8 } },
                            [
                              _c("h3", { staticClass: "state-name" }, [
                                _vm._v("C.O.P. (S)"),
                              ]),
                              _vm.pumpOn.S
                                ? _c(
                                    "em",
                                    {
                                      staticClass: "lamp",
                                      staticStyle: {
                                        "background-color": "#5dc12b",
                                        "border-color": "#d4ffdd",
                                      },
                                    },
                                    [_vm._v("RUN")]
                                  )
                                : _vm._e(),
                              !_vm.pumpOn.S
                                ? _c(
                                    "em",
                                    {
                                      staticClass: "lamp",
                                      staticStyle: {
                                        "background-color": "#d05050",
                                        "border-color": "#f1d6d6",
                                      },
                                    },
                                    [_vm._v("STOP")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "sensor-wp", attrs: { span: 17 } },
            [
              _c(
                "div",
                { staticClass: "sensor-header" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        fill: "#3c76af",
                        "text-color": "#ffffff",
                        size: "small",
                      },
                      model: {
                        value: _vm.sensorRadio,
                        callback: function ($$v) {
                          _vm.sensorRadio = $$v
                        },
                        expression: "sensorRadio",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("Tanks Status"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("Pumps and Line Pressure"),
                      ]),
                    ],
                    1
                  ),
                  _c("em", {
                    staticClass: "el-icon-refresh",
                    staticStyle: {
                      "font-size": "18px",
                      padding: "10px",
                      cursor: "pointer",
                      color: "#fff",
                    },
                    on: { click: _vm.reload },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sensorRadio === "1",
                      expression: "sensorRadio==='1'",
                    },
                  ],
                  staticClass: "progress-group",
                },
                _vm._l(_vm.tanksP, function (item) {
                  return _c(
                    "dv-border-box-12",
                    {
                      key: item.name,
                      staticClass: "progress-item",
                      attrs: {
                        color: ["#24486f", "#1ec8da"],
                        backgroundColor: "#041d35",
                      },
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(item.name))]),
                      _c("div", { staticClass: "percentage-text" }, [
                        _vm._v(_vm._s(item.percentage) + "%"),
                      ]),
                      _c("div", {
                        staticClass: "percentage-float",
                        style: { height: `${item.percentage}%` },
                      }),
                      _c(
                        "el-row",
                        {
                          staticClass: "progress-row",
                          attrs: { type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "progress-text",
                              attrs: { span: 24 },
                            },
                            [
                              _c("p", [
                                _c("span", [_vm._v(_vm._s(_vm.$t("temp")))]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(item.temperature) + "℃"),
                                ]),
                              ]),
                              _c("p", [
                                _c("span", [_vm._v(_vm._s(_vm.$t("level")))]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.level) + "m")]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sensorRadio === "1",
                      expression: "sensorRadio==='1'",
                    },
                  ],
                  staticClass: "progress-group",
                },
                _vm._l(_vm.tanksS, function (item) {
                  return _c(
                    "dv-border-box-12",
                    {
                      key: item.name,
                      staticClass: "progress-item",
                      attrs: {
                        color: ["#24486f", "#1ec8da"],
                        backgroundColor: "#041d35",
                      },
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(item.name))]),
                      _c("div", { staticClass: "percentage-text" }, [
                        _vm._v(_vm._s(item.percentage) + "%"),
                      ]),
                      _c("div", {
                        staticClass: "percentage-float",
                        style: { height: item.percentage + "%" },
                      }),
                      _c(
                        "el-row",
                        {
                          staticClass: "progress-row",
                          attrs: { type: "flex" },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "progress-text",
                              attrs: { span: 24 },
                            },
                            [
                              _c("p", [
                                _c("span", [_vm._v(_vm._s(_vm.$t("temp")))]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(item.temperature) + "℃"),
                                ]),
                              ]),
                              _c("p", [
                                _c("span", [_vm._v(_vm._s(_vm.$t("level")))]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.level) + "m")]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 18 } }, [
                    _vm.sensorRadio === "2"
                      ? _c(
                          "div",
                          { staticClass: "watchs" },
                          [
                            _c(
                              "dv-border-box-12",
                              {
                                staticClass: "watchs-item",
                                attrs: {
                                  color: ["#24486f", "#1ec8da"],
                                  backgroundColor: "#041d35",
                                },
                              },
                              [
                                _c("h3", [_vm._v("NO.1 H.F.O C.O.P")]),
                                _c(
                                  "el-row",
                                  { attrs: { type: "flex" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "NO.1 H.F.O C.O.P INLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar1,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("INLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "NO.1 H.F.O C.O.P OUTLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar2,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("OUTLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "dv-border-box-12",
                              {
                                staticClass: "watchs-item",
                                attrs: {
                                  color: ["#24486f", "#1ec8da"],
                                  backgroundColor: "#041d35",
                                },
                              },
                              [
                                _c("h3", [_vm._v("NO.2 H.F.O C.O.P")]),
                                _c(
                                  "el-row",
                                  { attrs: { type: "flex" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "NO.2 H.F.O C.O.P INLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar1,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("INLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "NO.2 F.F.O C.O.P OUTLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar2,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("OUTLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.sensorRadio === "2"
                      ? _c(
                          "div",
                          { staticClass: "watchs" },
                          [
                            _c(
                              "dv-border-box-12",
                              {
                                staticClass: "watchs-item",
                                attrs: {
                                  color: ["#24486f", "#1ec8da"],
                                  backgroundColor: "#041d35",
                                },
                              },
                              [
                                _c("h3", [_vm._v("D.O.C.O.P")]),
                                _c(
                                  "el-row",
                                  { attrs: { type: "flex" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "D.O.C.O.P INLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar3,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("INLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "D.O.C.O.P OUTLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar2,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("OUTLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "dv-border-box-12",
                              {
                                staticClass: "watchs-item",
                                attrs: {
                                  color: ["#24486f", "#1ec8da"],
                                  backgroundColor: "#041d35",
                                },
                              },
                              [
                                _c("h3", [_vm._v("STRIPPING PUMP")]),
                                _c(
                                  "el-row",
                                  { attrs: { type: "flex" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "STRIPPING PUMP INLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar3,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("INLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("ve-gauge", {
                                          attrs: {
                                            data: _vm.pressureData(
                                              "STRIPPING PUMP OUTLET"
                                            ),
                                            height: "125px",
                                            width: "125px",
                                            settings: _vm.chartBar2,
                                          },
                                        }),
                                        _c("h3", { staticClass: "detail" }, [
                                          _vm._v("OUTLET"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _vm.sensorRadio === "2"
                        ? _c(
                            "dv-border-box-12",
                            {
                              staticClass: "watchs",
                              staticStyle: { "margin-top": "8px" },
                              attrs: {
                                color: ["#24486f", "#1ec8da"],
                                backgroundColor: "#041d35",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "watchs-item watchs-column" },
                                [
                                  _c("ve-gauge", {
                                    attrs: {
                                      data: _vm.pressureData("NO.1 CARGO PIPE"),
                                      height: "120px",
                                      width: "120px",
                                      settings: _vm.chartBar2,
                                    },
                                  }),
                                  _c("h3", [_vm._v("NO.1 CARGO PIPE")]),
                                  _c("ve-gauge", {
                                    attrs: {
                                      data: _vm.pressureData("NO.2 CARGO PIPE"),
                                      height: "120px",
                                      width: "120px",
                                      settings: _vm.chartBar2,
                                    },
                                  }),
                                  _c("h3", [_vm._v("NO.2 CARGO PIPE")]),
                                  _c("ve-gauge", {
                                    attrs: {
                                      data: _vm.pressureData("NO.3 CARGO PIPE"),
                                      height: "120px",
                                      width: "120px",
                                      settings: _vm.chartBar2,
                                    },
                                  }),
                                  _c("h3", [_vm._v("NO.3 CARGO PIPE")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mass-flow" },
        [
          _c("dv-scroll-board", {
            staticClass: "iot-list",
            attrs: { config: _vm.flowmeterConfig },
          }),
        ],
        1
      ),
      _c("dv-border-box-12", {
        staticClass: "mass-flow-border",
        attrs: { color: ["#24486f", "#1ec8da"] },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }